<div class="content-header">
	<div class="container-fluid">
		<div class="row mb-2">
			<div class="col-sm-12">
				<ol class="breadcrumb float-sm-left">
					<li class="breadcrumb-item">
						<a [routerLink]="['/']">
							<i class="fas fa-home"></i> Página Inicial</a
						>
					</li>
					<li class="breadcrumb-item active">
						Responder Questionário
					</li>
				</ol>
			</div>
		</div>
	</div>
</div>

<div [class]="model.status == 'Enviado' ? '' : 'minuta'">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-lightblue">
          <div class="card-header">
            <h3 class="card-title mb-0">
              <i *ngIf="this.id" class="fas fa-pencil-alt pr-2"></i>
              <i *ngIf="!this.id" class="far fa-file pr-2"></i> Responder Questionário
            </h3>
          </div>

          <div class="card-body">

            <div class="row">
              <div class="form-group col-md-4">
                <label for="pea" class="mb-1">Nome da Funcionalidade </label>
                <input
                  type="text"
                  disabled
                  id="pea"
                  class="form-control form-control-sm"
                  [ngModel]="model.nomeFuncionalidade"
                  />
              </div>

              <div class="form-group col-md-2">
                <label for="siglaUg" class="mb-1">Sigla UG</label>
                <input
                  type="text"
                  disabled
                  id="siglaUg"
                  class="form-control form-control-sm"
                  [ngModel]="model.siglaUG"
                  />
              </div>

              <div class="form-group col-md-2">
                <label for="responsavel" class="mb-1">Responsável</label>
                <input
                  type="text"
                  disabled
                  id="responsavel"
                  class="form-control form-control-sm"
                  [ngModel]="model.responsavel"
                  />
              </div>

              <div class="form-group col-md-2">
                <label for="dataEnvio" class="mb-1">Data Envio</label>
                <input
                  type="text"
                  disabled
                  id="dataEnvio"
                  class="form-control form-control-sm"
                  [ngModel]="model.dataEnvio"
                  />
              </div>

              <div class="form-group col-md-2">
                <label for="dataPrazo" class="mb-1">Data Prazo</label>
                <input
                  type="text"
                  disabled
                  id="dataPrazo"
                  class="form-control form-control-sm"
                  [ngModel]="model.dataPrazo"
                  />
              </div>
            </div>
      <div class="row">
          <button
            type="button"
            *ngIf="exibeCamposStatusEmReplica"
            class="btn btn-danger btn-sm mr-1"
            (click)="onContestar()"> 
            <i class="fas fa-check"></i> Contestar
          </button>
          <button
          type="button"
          *ngIf="exibeCamposStatusEmReplica"
          class="btn btn-primary btn-sm mr-1"
          (click)="onConcordar()"> 
          <i class="fas fa-pencil-alt"></i> Concordar
          </button>
      </div>      


  <div class="container">
      <form [formGroup]="formGroup">
        <div formArrayName="respostas">
        <table *ngIf="dataSource && displayedColumns.length" mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <ng-container matColumnDef="checkbox">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element; let index = index">
              <mat-checkbox [value]="element.idItemQuestionario" [formControl]="getCheckBoxFormControl(index)" (change)="onCheckboxChange($event, element)" [disabled]="visualizar"></mat-checkbox>
            </td>
          </ng-container>
          
    
          <ng-container matColumnDef="question">
            <th mat-header-cell *matHeaderCellDef> 
              <!-- <mat-checkbox
                (change)="toggleSelectAll($event.checked)"
                [checked]="isAllSelected()"
                [indeterminate]="isIndeterminate()">
              </mat-checkbox> -->
              Pergunta/Seção 
            </th>
            <td mat-cell *matCellDef="let element"> {{element.pergunta}} </td>
          </ng-container>
    
          <ng-container matColumnDef="responseType">
            <th mat-header-cell *matHeaderCellDef> Tipo de Entrada </th>
            <td mat-cell *matCellDef="let element"> {{element.tipoEntrada}} </td>
          </ng-container>
    
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element"> {{element.status}} </td>
          </ng-container>
    
          <ng-container matColumnDef="response">
            <th mat-header-cell *matHeaderCellDef> Resposta </th>
            <td mat-cell *matCellDef="let element; let index = index">
              <ng-container [ngSwitch]="element.tipoEntrada">
                <mat-form-field *ngSwitchCase="'Somente texto'" appearance="outline" class="example-form-field">
                  <input matInput [formControl]="getRespostaFormControl(index)" />
                </mat-form-field>
                <mat-form-field *ngSwitchCase="'Numérico Inteiro'" appearance="outline" class="example-form-field">
                  <input matInput type="number" [formControl]="getRespostaFormControl(index)" />
                </mat-form-field>
                <mat-form-field *ngSwitchCase="'Numérico Decimal'" appearance="outline" class="example-form-field">
                  <input matInput type="number" [formControl]="getRespostaFormControl(index)" />
                </mat-form-field>
                <mat-form-field *ngSwitchCase="'Texto com anexo opcional'" appearance="outline" class="example-form-field">
                  <input matInput [formControl]="getRespostaFormControl(index)" class="custom-input" />
                  <input type="file" (change)="onFileSelected($event, index)" class="form-control-file selecionar-arquivo mr-2" [disabled]="visualizar"/>
                  <div *ngIf="element.arquivo" class="file-attachment">
                    <a [href]="element.urlArquivo" download="{{element.arquivo?.nomeArquivo}}" class="link-download">
                      {{element.arquivo?.nomeArquivo}}
                    </a>
                  </div>
                </mat-form-field>
                <mat-form-field *ngSwitchCase="'Texto com anexo obrigatório'" >
                  <input matInput [formControl]="getRespostaFormControl(index)" class="custom-input" />
                  <input type="file" (change)="onFileSelected($event, index)" class="form-control-file selecionar-arquivo mr-2" [disabled]="visualizar"/>
                  <div *ngIf="element.arquivo" class="file-attachment">
                    <a [href]="element.urlArquivo" download="{{element.arquivo?.nomeArquivo}}" class="link-download">
                      {{element.arquivo?.nomeArquivo}}
                    </a>
                  </div>
                </mat-form-field>
                <mat-form-field *ngSwitchCase="'Múltipla Escolha'" appearance="outline">
                  <mat-select [formControl]="getRespostaFormControl(index)">
                    <mat-option *ngFor="let item of element.perguntaOpcao" [value]="item.nome">
                      {{ item.nome }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                            
              </ng-container>
            </td>
          </ng-container>
    
          <ng-container matColumnDef="assignedTo">
            <th mat-header-cell *matHeaderCellDef> Delegado a </th>
            <td mat-cell *matCellDef="let element"> {{element.nomeDelegadoA}} </td>
          </ng-container>
          
          <ng-container matColumnDef="avaliacaoAuditor" *ngIf="exibeCamposStatusEmReplica">
            <th mat-header-cell *matHeaderCellDef> Avaliação do Auditor </th>
            <td mat-cell *matCellDef="let element" [attr.disabled]="true"> {{element.tipoAvaliacaoAuditor}} </td>
          </ng-container>
          

          <ng-container matColumnDef="respostaRevisadaAuditor" *ngIf="exibeCamposStatusEmReplica">
            <th mat-header-cell *matHeaderCellDef> Resposta Revisada do Auditor </th>
            <td mat-cell *matCellDef="let element"[attr.disabled]="true"> <a class="e2e-inner-html-bound" [innerHTML]="element.avaliacaoAuditor"></a></td>
          </ng-container>


    
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    
        <div class="card-footer">
          <div class="btn-group" [hidden]="visualizar || imprimir">
            <button
              type="button"
              class="btn btn-primary btn-sm"
              (click)="onSalvar(false)">
              <i class="far fa-save"></i> Salvar
            </button>
            <button
              type="button"
              class="btn btn-primary btn-sm dropdown-toggle dropdown-toggle-split"
              data-toggle="dropdown"
              aria-expanded="false">
            </button>
            <div class="dropdown-menu" role="menu">
              <a class="dropdown-item" (click)="onSalvar(false)">Salvar</a>
              <a class="dropdown-item" (click)="onSalvar(true)">Salvar e fechar</a>
            </div>
          </div>
          
          <button
            type="button"
            *ngIf="!visualizar && !imprimir"
            class="btn btn-warning btn-sm mr-1"
            (click)="onEnviar()"
            [disabled]="!isAllFinalized()"> 
            <i class="fas fa-paper-plane"></i> Enviar
          </button>

          <button
          type="button"
          *ngIf="!visualizar && !imprimir"
          class="btn btn-warning btn-sm mr-1"
          (click)="onFinalizar()"
          [disabled]="!isHabilitaBotaoFinalizar()">
          <i class="fas fa-list"></i> Finalizar
        </button>

          <button
            type="button"
            *ngIf="!visualizar"
            class="btn btn-default btn-sm"
            (click)="onCancelar()">
            <i class="fas fa-times"></i> Fechar
          </button>

          <button
            type="button"
            *ngIf="!visualizar && !imprimir"
            class="btn btn-danger btn-sm"
            (click)="modalDelegar()">
            <i class="fas fa-share-square"></i> Delegar
          </button>

          <button
            type="button"
            *ngIf="visualizar"
            class="btn btn-default btn-sm"
            (click)="onCancelar()">
            <i class="fas fa-times"></i> Sair
          </button>


        <button
        type="button"
        *ngIf="imprimir"
        class="btn btn-primary btn-sm mr-1"
        (click)="print()"
        >
        <i class="fas fa-print"></i> Imprimir
        </button> 
        </div>
      </div>
      </form>
    </div>
  </div>
  </div>
  </div>
  </div>
</div>
  
  