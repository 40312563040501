import { Component, OnInit, ViewChild } from "@angular/core";
import { BaseFormComponent } from "src/app/shared/components/base-form/base-form.component";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { MatDialog,	MatDialogConfig } from "@angular/material/dialog";
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';
import { formatDate } from '@angular/common';


//COMPONENTS
import { ModalConfirmarReativacaoDistribuicaoComponent } from "../modal-confirmar-reativacao-distribuicao/modal-confirmar-reativacao-distribuicao.component";
import { ModalConfirmarInativacaoComponent } from "src/app/shared/components/modal-confirmar-inativacao/modal-confirmar-inativacao.component";
import { ModalSelecionarColunasComponent } from "../modal-selecionar-colunas/modal-selecionar-colunas.component";
import { ModalGraficoDistribuicaoComponent } from "../modal-grafico-distribuicao/modal-grafico-distribuicao.component";
import { ModalSelecionarUGComponent } from "../modal-selecionar-ug/modal-selecionar-ug.component";
import { ModalAlterarTipoAuditoriaComponent } from "../modal-alterar-tipo-auditoria/modal-alterar-tipo-auditoria.component";

//MODELS
import { IPaaAbaDistribuicaoModel } from "src/app/models/paa-aba-distribuicao.model";
import { IPaaDistribuicaoModel } from "src/app/models/paa-distribuicao.model";
import { IPaaInformacoesBasicasModel } from "src/app/models/paa-informacoes-basicas.model";
import { FiltroModel } from "src/app/models/filtro.model";


//SERVICES
import { PaaService } from "src/app/services/paa.service";
import { PaaAbaDistribuicaoService } from "src/app/services/paa/paa-aba-distribuicao.service";
import { MatSort } from "@angular/material/sort";
import { IPaaDistribuicaoColunasModel } from "src/app/models/paa-distribuicao-colunas.model";
import { ITipoAuditoriaModel } from "src/app/models/tipo-auditoria.model";
import { ITipoEnfaseModel } from "src/app/models/tipo-enfase.model";
import { TipoAuditoriaService } from "src/app/services/tipo-auditoria.service";
import { TipoEnfaseService } from "src/app/services/tipo-enfase.service";
import { UnidadeGestoraService } from "src/app/services/unidade-gestora.service";




@Component({
	selector: "app-paa-aba-distribuicao",
	templateUrl: "./paa-aba-distribuicao.component.html",
	styleUrls: ["./paa-aba-distribuicao.component.scss"],
})
export class PaaAbaDistribuicaoComponent extends BaseFormComponent implements OnInit{
  
  public model = {} as IPaaAbaDistribuicaoModel;

  public distribuicaoDataSource = new MatTableDataSource<IPaaDistribuicaoModel>([]);
  public distribuicaoNaoContempladosDataSource = new MatTableDataSource<any>([]);
  public distribuicaoNaoContempladosPersonalizadoDataSource = new MatTableDataSource<any>([]);
  public distribuicaoPersonalizadoDataSource = new MatTableDataSource<any>([]);
  public paaInformacoesBasicas: IPaaInformacoesBasicasModel = {} as IPaaInformacoesBasicasModel;
  public tiposAuditorias: ITipoAuditoriaModel[];
  public tiposEnfase: ITipoEnfaseModel[];
  public filtroEnfase = {} as FiltroModel;
  
  public columnsDistribuicaoOriginais1 = ['unidadeGestora', 'valorRiscoResidual'];
  public columnsDistribuicaoOriginais2 = ['tipoAuditoria','tipoEnfase', 'actions', 'ativo'];
  public columnsDistribuicao = [];
  public colunasAdicionarDistribuicao = [];
  public columnsDistribuicaoPersonalizado = [];

  public distribuicaoNaoContempladosSemDados = true;
  public distribuicaoSemDados = true;
  public historico:boolean;
  public paaHomologado = true;
  public distruibuicaoSemDados = true;
  public distribuicaoNaoContempladosSemDadosPersonalizado = true;
  public distribuicaoSemDadosPersonalizado = true;
  public isOrigemPlanejada:boolean;

  @ViewChild('tabela3') public tabela3: MatSort;

  constructor(route: ActivatedRoute,
              toastr: ToastrService,
              router: Router,
              localeService: BsLocaleService,
              private _location: Location,
              private paaService: PaaService,
              private paaAbaDistribuicaoService: PaaAbaDistribuicaoService,
              private tipoAuditoriaService: TipoAuditoriaService,
              private tipoEnfaseService: TipoEnfaseService,
              private unidadeGestoraService: UnidadeGestoraService,
              public matDialog: MatDialog
  ){super (route, toastr, router, localeService, matDialog);}




    
    //METHODS

    async ngOnInit() {
    
      //TODO: VERIFICAR PQ A VARIÁVEL VISUALIZAR ESTÁ SENDO RESETADA
      this.visualizar = this.route.routeConfig.path?.includes("visualizar");

      await this.obterInformacoesBasicasPaa();
      await this.obterTiposAuditorias();
      await this.obterTiposEnfase();
      this.obterPainelDistribuicao()

    }

    public async obterInformacoesBasicasPaa(){
      this.paaService.buscarInformacoesBasicas(this.id)
          .then((res)=> {
            if(res.sucesso){
              
              this.paaInformacoesBasicas = res.dados;
              
              this.paaHomologado = this.paaInformacoesBasicas.idSituacao == 4;
              this.isOrigemPlanejada = this.paaInformacoesBasicas.origem == 1;
            }
          })
    }

 

    public async obterPainelDistribuicao() {        
            return this.paaAbaDistribuicaoService.obterPainelDistribuicao(this.id).then((res) => {              
        this.colunasAdicionarDistribuicao = [];
        this.columnsDistribuicao = [];
        this.columnsDistribuicao = this.columnsDistribuicao.concat(
          Object.assign([], this.columnsDistribuicaoOriginais1)
        );
        
        var colunasNomes: any = {};
        if (res && res.dados[0] && res.dados[0].colunasValores) {
          res.dados[0].colunasValores.forEach((element) => {
            const nomeColuna = this.obterNomeColuna(element);
    
            colunasNomes[nomeColuna.toString()] = nomeColuna;
    
            this.columnsDistribuicao.push(nomeColuna);
            if (!this.colunasAdicionarDistribuicao.some((c) => c === nomeColuna)) {
              this.colunasAdicionarDistribuicao.push(nomeColuna);
            }
          });
        }
    
        this.columnsDistribuicao = this.columnsDistribuicao.concat(
          Object.assign([], this.columnsDistribuicaoOriginais2)
        );
        var dados = res.dados
          .map((item) => {
            if (item.tipoAuditoria?.id == 0) item.tipoAuditoriaId = null;
            item.unidadeGestora.sigla = item.unidadeGestora.sigla.toUpperCase();
            return item;
          });
    
        this.distribuicaoDataSource = new MatTableDataSource<IPaaDistribuicaoModel>(dados);
        this.distribuicaoDataSource._updateChangeSubscription();
    

        this.distribuicaoDataSource.sortingDataAccessor = (item: IPaaDistribuicaoModel, property) => {
          switch (property) {
            case 'unidadeGestora':
              return item.unidadeGestora.sigla;
    
            case 'valorRiscoRiscoresidual':
              return item.valorRiscoResidual;
    
            case colunasNomes[property]:
              return parseFloat(
                this.obterValorColuna(item, property).replace(/[.]/g, '').replace(/[,]/g, '.') == ''
                  ? '0'
                  : this.obterValorColuna(item, property).replace(/[.]/g, '').replace(/[,]/g, '.')
              );
    
            case 'ativo':
              return item.ativo.toString();
    
            case 'tipoAuditoria':
              return item.tipoAuditoria.nome;
    
            default:
              return item[property];
          }
        };
    
        this.distribuicaoDataSource.sort = this.tabela3;
        this.distruibuicaoSemDados = this.distribuicaoDataSource.filteredData.length === 0;
    
        res.dados.forEach((item) => {
          this.obterHistoricoDistribuicao(item);
        });
      });
    }
    
    
    
    
    
    
      public obterValorColuna(
        element: IPaaDistribuicaoModel,
        coluna: string
      ): string {
        if (!element || !coluna) {
          return '';
        }
    
        const result = element.colunasValores.find(
          (x) => this.obterNomeColuna(x) === coluna
        );
    
        if (!result || !result.valorNumerico) {
          return '';
        }
    
        return result.valorNumerico.toFixed(2);
      }
    
      private obterNomeColuna(element: IPaaDistribuicaoColunasModel) {
        return element.nomeIndicador && element.nomeIndicador !== ''
          ? element.nomeIndicador + ' / ' + element.nomeCriterio
          : element.nomeCriterio;
      }
    
      public reativarDistribuicao(element: IPaaDistribuicaoModel) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.id = 'modal-component';
        dialogConfig.width = '650px';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;
        dialogConfig.data = {titulo: "Tem certeza que deseja reativar esse registro?"};
        const modal = this.matDialog.open(
          ModalConfirmarReativacaoDistribuicaoComponent,
          dialogConfig
        );
    
        modal.afterClosed().subscribe((data) => {
          if (data) {
    
            this.paaAbaDistribuicaoService
              .AlterarStatusDistribuicao(element.id, !element.ativo, data)
              .then((resAssociar) => {
                if (resAssociar.sucesso) {
                  this.exibirMensagemSucesso('Status alterado com sucesso');
                } else {
                  this.exibirMensagemAlerta(resAssociar.mensagem.descricao);
                }
              })
              .catch((errAssociar) => {
                this.exibirMensagemErro(errAssociar.mensagem.descricao);
              })
              .finally(() => {
                this.obterPainelDistribuicao();
              });
          }
        });
      }
    
      public inativarDistribuicao(element: IPaaDistribuicaoModel) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.id = 'modal-component';
        dialogConfig.width = '650px';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;
        dialogConfig.data = null;
        const modal = this.matDialog.open(
          ModalConfirmarInativacaoComponent,
          dialogConfig
        );
    
        modal.afterClosed().subscribe((data) => {
          if (data) {
              
            this.paaAbaDistribuicaoService
              .AlterarStatusDistribuicao(element.id, false, data)
              .then((resAssociar) => {
                if (resAssociar.sucesso) {
                  this.exibirMensagemSucesso('Status alterado com sucesso');
                } else {
                  this.exibirMensagemAlerta(resAssociar.mensagem.descricao);
                }
              })
              .catch((errAssociar) => {
                this.exibirMensagemErro(errAssociar.mensagem.descricao);
              })
              .finally(() => {
                this.obterPainelDistribuicao();
              });
          }
        });
      }

      
      public agrupadorCapacidade(index: number, item: any): boolean {
        return item.grupo;
      }

      public obterHistoricoDistribuicao(
        element: IPaaDistribuicaoModel
      ): Promise<void> {
      
        return this.paaAbaDistribuicaoService
          .obterHistoricoDistribuicao(this.id, element.unidadeGestoraId)
          .then((historico) => {
            if (historico.sucesso) {
              let tooltipSituacao = '';
              let tooltipTipoEnfase = '', tooltipTipoAuditoria = '';
      
              if (historico.dados.length > 0) {             
                historico.dados.forEach((item) => {
                  element.historicoSituacao = true;
                  if (item.acao == 'Inativação' || item.acao == "Reativação") {
                    tooltipSituacao += `${item.usuario} - ${formatDate(
                      item.data,
                      'dd/MM/yyyy',
                      'pt-BR'
                    )}, Ação: ${item.acao}, Justificativa: ${item.justificativa}\n\n`;
                  }
      
                  if (item.acao.toLowerCase().includes('ênfase')) {
                    element.historicoTipoEnfase = true;
      
                    if (item.justificativa && item.justificativa.trim().length > 0) {
                      tooltipTipoEnfase = ` Justificativa: ${item.justificativa}\n\n`;
                    }
                  }
      
                  if (item.acao.toLowerCase().includes('auditoria')) {
                    element.historicoTipoAuditoria = true;
                    tooltipTipoAuditoria = ` Justificativa: ${item.justificativa}\n\n`;
                  }
                });
                element.tooltipHistoricoSituacao = tooltipSituacao;
                element.tooltipHistoricoAuditoria = tooltipTipoAuditoria;
                element.tooltipHistoricoEnfase = tooltipTipoEnfase;
                element.historico = true;
              } else {
                tooltipSituacao += 'Nenhum dado encontrado.';
                tooltipTipoAuditoria += 'Nenhum dado encontrado.';
                tooltipTipoEnfase += 'Nenhum dado encontrado.';
                element.historico = false;
              }
      
             
      
            } else {
              this.exibirMensagemErro(historico.mensagem.descricao);
            }
          });
      }
      


      public async obterTiposAuditorias() {
        return this.tipoAuditoriaService.obter('', true).then((res) => {
          this.tiposAuditorias = res.dados;
        });
      }
    
      public async obterTiposEnfase(){
        this.filtroEnfase.itensPorPagina = 99;
        this.filtroEnfase.ativo = true;
        return this.tipoEnfaseService.obter(this.filtroEnfase).then((res) =>
        {
          this.tiposEnfase = res?.dados.resultado;
        })
      }
    

       public alterarTipoAuditoria(element: IPaaDistribuicaoModel) {
        const dialogConfigJustificativa = new MatDialogConfig();
        dialogConfigJustificativa.id = 'modal-component';
        dialogConfigJustificativa.width = '650px';
        dialogConfigJustificativa.hasBackdrop = true;
        dialogConfigJustificativa.disableClose = true;
        dialogConfigJustificativa.data = {titulo: "Deseja realmente alterar o tipo de auditoria?"};
        const modalJustificativa = this.matDialog.open(
          ModalAlterarTipoAuditoriaComponent,
          dialogConfigJustificativa
        );
        modalJustificativa.afterClosed().subscribe((item) => {
          if(item){
            this.paaAbaDistribuicaoService.ValidarTipoAuditoria(element.id, element.tipoAuditoriaId).then((res) => {
              if (res == null) {
                this.paaAbaDistribuicaoService
                  .AlterartipoAuditoriaDistribuicao(element.id, element.tipoAuditoriaId, item)
                  .then((resAssociar) => {
                    if (resAssociar.sucesso) {
                      this.exibirMensagemSucesso(resAssociar.mensagem.descricao);
                      this.obterPainelDistribuicao();
                    } else {
                      this.exibirMensagemErro(resAssociar.mensagem.descricao);
                    }
                  })
                  .catch((errAssociar) => {
                    this.exibirMensagemErro(errAssociar.mensagem.descricao);
                  });
              }
              if (res.sucesso) {
                Swal.fire({
                  title: 'Tipo de auditoria',
                  text: res.mensagem.descricao,
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'Sim',
                  cancelButtonText: 'Não',
                }).then(resConfirm => {
                  if (resConfirm.isConfirmed) {
                    this.paaAbaDistribuicaoService
                      .AlterartipoAuditoriaDistribuicao(element.id, element.tipoAuditoriaId, item)
                      .then((resAssociar) => {
                        if (resAssociar.sucesso) {
                          this.exibirMensagemSucesso(resAssociar.mensagem.descricao);
                        } else {
                          this.exibirMensagemErro(resAssociar.mensagem.descricao);
                        }
                      })
                      .catch((errAssociar) => {
                        this.exibirMensagemErro(errAssociar.mensagem.descricao);
                      });
                  }
                });
              }
              else {
                this.exibirMensagemErro(res.mensagem.descricao);
              }
            }).catch((erro) => {
              this.exibirMensagemErro(erro.mensagem.descricao);
            })
          }
        });
       }

       public alterarTipoEnfase(element: IPaaDistribuicaoModel) {
        const dialogConfigJustificativaTipoEnfase = new MatDialogConfig();
        dialogConfigJustificativaTipoEnfase.id = 'modal-component';
        dialogConfigJustificativaTipoEnfase.width = '650px';
        dialogConfigJustificativaTipoEnfase.hasBackdrop = true;
        dialogConfigJustificativaTipoEnfase.disableClose = true;
        dialogConfigJustificativaTipoEnfase.data = {titulo: "Deseja atribuir essa ênfase?"};
        const modalJustificativaTipoEnfase = this.matDialog.open(
          ModalConfirmarReativacaoDistribuicaoComponent,
          dialogConfigJustificativaTipoEnfase
        );
    
        modalJustificativaTipoEnfase.afterClosed().subscribe((item) => {
          if(item){
            this.paaAbaDistribuicaoService
                .AlterarTipoEnfaseDistribuicao(element.id, element.tipoEnfaseId, item)
                .then((resAssociar) => {
                  if (resAssociar.sucesso) {
                    this.obterPainelDistribuicao();
                    this.exibirMensagemSucesso(resAssociar.mensagem.descricao);
                  } else {
                    this.exibirMensagemErro(resAssociar.mensagem.descricao);
                  }
                })
                .catch((errAssociar) => {
                  this.exibirMensagemErro(errAssociar.mensagem.descricao);
                });
          }
          
              })
       }


       public editarColunas() {

        this.paaAbaDistribuicaoService
          .obterPainelDistribuicaoColunas(this.id)
          .then((res) => {
            if (res.sucesso) {
              const dialogConfig = new MatDialogConfig();
              dialogConfig.id = 'modal-component';
              dialogConfig.width = '650px';
              dialogConfig.hasBackdrop = true;
              dialogConfig.disableClose = true;
              dialogConfig.data = res.dados;
              const modal = this.matDialog.open(
                ModalSelecionarColunasComponent,
                dialogConfig
              );
    
              modal.afterClosed().subscribe((data) => {
                if (data) {
                  this.paaService
                    .AlterarSatusDistribuicaoColunas(data)
                    .then((resAssociar) => {
                      if (resAssociar.sucesso) {
                        this.exibirMensagemSucesso('Colunas alteradas com sucesso');
                        this.obterPainelDistribuicao();
                      } else {
                        this.exibirMensagemErro(resAssociar.mensagem.descricao);
                      }
                    })
                    .catch((errAssociar) => {
                      this.exibirMensagemErro(errAssociar.mensagem.descricao);
                    });
                }
              });
            } else {
              this.exibirMensagemErro(res.mensagem.descricao);
            }
          })
          .catch((err) => {
            this.exibirMensagemErro(err.mensagem.descricao);
          })
      }

      public visualizarGrafico() {

        this.paaService
          .obterGrafico(this.id)
          .then((res) => {
            if (res.sucesso) {
              const dialogConfig = new MatDialogConfig();
              dialogConfig.id = 'modal-component';
              // dialogConfig.width = '650px';
              dialogConfig.maxHeight = '100%';
              dialogConfig.hasBackdrop = true;
              dialogConfig.disableClose = true;
              dialogConfig.data = res.dados;
              const modal = this.matDialog.open(
                ModalGraficoDistribuicaoComponent,
                dialogConfig
              );
            } else {
              this.exibirMensagemErro(res.mensagem.descricao);
            }
          })
          .catch((err) =>
            this.exibirMensagemErro(err.mensagem.descricao)
          )
      }

      public cancelar() {
        this.router.navigate(['/paa']);
        }

  //COMPUTEDS

  mostrarSelectUnidade(): boolean{
    return !this.visualizar && !this.paaHomologado;
  }

  mostrarTipoEnfase() : boolean{
    return !this.visualizar && !this.paaHomologado;
  }

  mostrarBotaoEditarColunas() : boolean{
    return !this.visualizar && !this.paaHomologado;
  }

  mostrarBotaoIncluirNovaUG(): boolean{
    
    return !this.visualizar && !this.paaHomologado && this.isOrigemPlanejada;
  }

  mostrarBotaoGrafico() : boolean{
    return !this.visualizar && !this.paaHomologado;
  }

  mostrarBotaoAtivarInativar(): boolean{
    if(this.isOrigemPlanejada){
      this.columnsDistribuicaoOriginais2 = ['tipoAuditoria','tipoEnfase', 'actions', 'ativo'];
    }else{
      this.columnsDistribuicaoOriginais2 = ['tipoAuditoria','tipoEnfase', 'ativo'];
    }
    
    return !this.visualizar && this.isOrigemPlanejada;
  }
  
  public subTotalCapacidade(index: number, item: any): boolean {
    return item.subTotal;
  }

  public async incluirUG() {

    this.unidadeGestoraService.obter({ pagina: 1, itensPorPagina: 10000, ativo: true } as FiltroModel)
      .then((res) => {
        if (res.sucesso) {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.id = 'modal-component';
          dialogConfig.width = '650px';
          dialogConfig.maxHeight = '80vh';
          dialogConfig.hasBackdrop = true;
          dialogConfig.disableClose = true;
          
          let dados = res.dados.resultado.filter(
            (_) => !this.distribuicaoDataSource.data.find((d) => _.id === d.id)
          );
          dialogConfig.data = {dados: dados, modoSelecaoMultiplo: false};
          const modal = this.matDialog.open(
            ModalSelecionarUGComponent,
            dialogConfig
          );

          modal.afterClosed().subscribe(async (data) => {
            const dialogConfigJustificativa = new MatDialogConfig();
            dialogConfigJustificativa.id = 'modal-component';
            dialogConfigJustificativa.width = '650px';
            dialogConfigJustificativa.hasBackdrop = true;
            dialogConfigJustificativa.disableClose = true;
            dialogConfigJustificativa.data = {titulo: "Tem certeza que deseja incluir a UG?"};
            const modalJustificativa = this.matDialog.open(
              ModalConfirmarReativacaoDistribuicaoComponent,
              dialogConfig
            );

            modalJustificativa.afterClosed().subscribe((item) => {
              if (data && item) {
                const distribuicao = {} as IPaaDistribuicaoModel;
                distribuicao.ativo = true;
                distribuicao.paaId = this.id;
                distribuicao.unidadeGestoraId = data.id;
                distribuicao.manual = true;
                distribuicao.justificativa = item;
                const verificarDuplicidade = this.verificarDuplicidadeUG(
                  distribuicao.paaId,
                  distribuicao.unidadeGestoraId
                );

                if (verificarDuplicidade) {
                  (async () => {
                    const result = await this.paaService.adicionarDistribuicao(distribuicao);
                    try {
                      if (result.sucesso) {
                        this.exibirMensagemSucesso('Unidade Gerencial adicionada com sucesso');
                        this.obterPainelDistribuicao();
                      } else {
                        this.exibirMensagemErro(result.mensagem.descricao);
                      }
                    } catch (error) {
                      this.exibirMensagemErro(error.mensagem.descricao);
                    } finally {
                      this.obterPainelDistribuicao();
                    }
                  })()
                } else {
                  this.exibirMensagemErro('Essa UG já está incluida');
                }
              }
            });
          });
        } else {
          this.exibirMensagemErro(res.mensagem.descricao);
        }
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem.descricao);
      })
  }

  public async verificarDuplicidadeUG(
    distribuicao: number,
    unidadeGestoraId: number
  ) {
    const res = await this.paaService.obterPorId(distribuicao);
    if (!res.dados.paaUnidadeGestora || res.dados.paaUnidadeGestora.length <= 0) return true;

    const duplicada = res.dados.paaUnidadeGestora?.filter(
      (_) => _.unidadeGestoraId == unidadeGestoraId
    );

    if (duplicada?.length == 0) {
      return true;
    }
   
    var obrigatoria = duplicada?.filter(
      (_) => _.unidadeGestora.auditoriaObrigatoria == true
    );
    if (obrigatoria?.length > 0 && obrigatoria?.length < 2) {
      return true;
    }

    return false;
    

  }
  
  isRowEmpty(row: any): boolean {
    return row.valorRiscoResidual === 0 && !row.colunasValores.some((cv: any) => cv.valorNumerico != null);
}

  

}