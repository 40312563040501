import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IArquivoModel } from 'src/app/models/arquivo.model';
import { IListaQuestionarioRespostaUGModel, QuestionarioUGItensResposta } from 'src/app/models/questionario-ug.model';
import { AuthService } from 'src/app/services/auth.service';
import { EntendendoEntidadeService } from 'src/app/services/entendendo-entidade.service';
import { PerguntaService } from 'src/app/services/pergunta.service';
import { QuestionarioUGRespostaService } from 'src/app/services/questionarioug-resposta.service';
import { TabelaDominioService } from 'src/app/services/tabela-dominio.service';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';
import { ModalDelegarComponent } from './modal-delegar/modal-delegar.component';
import { ResponsaveisService } from 'src/app/services/responsaveis.service ';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ModalContestarComponent } from './modal-contestar/modal-contestar.component';
import Swal from 'sweetalert2';

interface Question {
  question: string;
  responseType: string;
  status: string;
  formControlName: string;
  assignedTo?: string;
}



@Component({
  selector: 'app-pre-planejamento-resposta-form',
  templateUrl: './pre-planejamento-resposta-form.component.html',
  styleUrls: ['./pre-planejamento-resposta-form.component.scss']
})
export class PrePlanejamentoRespostaFormComponent extends BaseFormComponent implements OnInit {
  public model= {} as IListaQuestionarioRespostaUGModel;
  formGroup: FormGroup;
  arquivo: File | null = null;
  displayedColumns: string[] = ['checkbox', 'question', 'responseType', 'status', 'response', 'assignedTo'];
  public dataSource = new MatTableDataSource<any>([]);
  imprimir: boolean;
  selectedItems: QuestionarioUGItensResposta[] = [];
  selectedIds: number[] = [];
  exibeCamposStatusEmReplica: boolean;
  habilitaBotaoFinalizar: boolean = false;
  
  constructor(private fb: FormBuilder,
    private questionarioUGService: QuestionarioUGRespostaService,
    toastr: ToastrService,
    route: ActivatedRoute,
    router: Router,
    private authService: AuthService,
    localeService: BsLocaleService,
    private perguntaService: PerguntaService,
    private tabelaDominioService: TabelaDominioService,
    private responsaveisService: ResponsaveisService,
    public matDialog: MatDialog
    ) { 
      super(route, toastr, router, localeService, matDialog);
      this.formGroup = this.fb.group({
        checkboxs: this.fb.array([]),
        respostas: this.fb.array([]),
        arquivos: this.fb.array([]) // Novo FormArray para os arquivos
    });
    this.unidadeGestoraId = 307;
 
  }
  private unidadeGestoraId = 307;


  ngOnInit(): void {
    this.buscar();
    this.route.data.subscribe(data => {
      this.imprimir = data['imprimir']; // Acesse o valor de imprimir dos dados da rota
    });
  }

  print(): void {
    window.print();
  }

  async onSalvar(salvarEFechar: boolean) {
    const respostasFormArray = this.formGroup.get('respostas') as FormArray;    
    this.dataSource.data.forEach((item, index) => {      
      item.resposta = respostasFormArray.at(index).value;
    });   
    const res = await this.questionarioUGService.salvarRespostasQuestionarioPorId(this.id, this.dataSource.data);
    if (res.sucesso) {
      if(salvarEFechar == true)
      {
        this.router.navigate([`/questionario/pre-planejamento`]);
      }
      else
      {
        this.exibirMensagemSucesso('Registro Salvo com Sucesso!')
        this.buscar();
        window.location.reload();
      }

    }
    else
    {
        this.exibirMensagemErro(res.mensagem.descricao);
    }
  
  }


async onEnviar(){
    const respostasFormArray = this.formGroup.get('respostas') as FormArray;
    this.dataSource.data.forEach((item, index) => {
      item.resposta = respostasFormArray.at(index).value;
    });
  
    this.questionarioUGService.enviarRespostasQuestionarioPorId(this.id, this.dataSource.data)
    .then((res) => {
      if (res.sucesso) {
        
        this.router.navigate([`/questionario/pre-planejamento`]);
      }
      else{
        this.exibirMensagemErro(res.mensagem.descricao);
      }
    })
    
  }
  onCancelar(){
    Swal.fire({
      title: 'Atenção',
      text: "Todas as informações preenchidas serão perdidas. Deseja continuar? ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((res) => {
      if (res.value) {
        this.router.navigate([`/questionario/pre-planejamento`]);
      }
    })
  }
  
  async modalDelegar(){
    const idsParaDelegar = this.selectedIds;
    const res = await this.responsaveisService.obter(
      this.model.idUG, '','','', true
    );
    
    const selectedItemIds = this.selectedItems.map(item => item.idItemQuestionario);
  
    if (res.sucesso) {
      try {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.id = 'modal-component';
        dialogConfig.width = '500px';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;
        dialogConfig.data = {
          questionarioId: idsParaDelegar,
          dados: res.dados,
        };
  
        const modal = this.matDialog.open(
          ModalDelegarComponent,
          dialogConfig
        );
  
        modal.afterClosed().subscribe(async (result) => {
          if (result === true) {
            this.submit = true;
            this.buscar();
            this.exibirMensagemSucesso("Registro delegado com sucesso");
          }
        });
      } catch (err) {
        this.exibirMensagemErro(err.mensagem.descricao);
      }
    }
    
  }
  
  enviarArquivo(){

  }

  onConcordar(){

  }

  onContestar(){

    const idsSelecionados = this.selectedIds;
    const idsSelecionadosString: string = idsSelecionados.join(',');
    const perguntaSelecionada = this.model.respostas.find(item => idsSelecionados.includes(item.idItemQuestionario));   
    // Verifica se há mais de uma pergunta selecionada
    if (idsSelecionados.length > 1) {
      this.exibirMensagemAlerta('Só pode ter um registro selecionado para contestar');
      return;
    }

    // Verifica se há pelo menos uma pergunta selecionada
    if (idsSelecionados.length === 0) {
      this.exibirMensagemAlerta('Selecione uma pergunta para contestar');
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'modal-component';
    dialogConfig.width = '1000px';
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      ids: idsSelecionadosString,
      pergunta: perguntaSelecionada.pergunta,
      resposta: perguntaSelecionada.resposta
    };
 
    const modal = this.matDialog.open(ModalContestarComponent, dialogConfig);
    modal.afterClosed().subscribe((data) => {
      if(data.success){
        this.buscar();
        window.location.reload();

       }
    })

  }

  onFinalizar(){
    const idsSelecionados = this.selectedIds;
    const idsSelecionadosString: string = idsSelecionados.join(',');
    this.questionarioUGService.FinalizarPerguntaQuestionario(idsSelecionadosString)
    .then((res) => {
      if (res.sucesso) {
        this.exibirMensagemSucesso("Registro finalizado com sucesso!");
        this.buscar();
        window.location.reload();
      }
      else{
        this.exibirMensagemErro(res.mensagem.descricao);
      }
    })

  }

  buscar() {
    this.questionarioUGService.ObterRespostaQuestionario(this.id)
      .then((res) => {
        if (res.sucesso) {
          this.model = res.dados;
          if(this.model.status == 'Em réplica')
          {
            this.exibeCamposStatusEmReplica = true;
            this.displayedColumns = ['checkbox', 'question', 'responseType', 'status', 'response', 'assignedTo', 'avaliacaoAuditor', 'respostaRevisadaAuditor'];
          }
          else
          {
            this.displayedColumns = ['checkbox', 'question', 'responseType', 'status', 'response', 'assignedTo'];
            this.exibeCamposStatusEmReplica = false;
          }
          this.dataSource = new MatTableDataSource<QuestionarioUGItensResposta>(res.dados.respostas);
          // Limpa o FormArray antes de adicionar novos controles
          const respostasFormArray = this.formGroup.get('respostas') as FormArray;
          respostasFormArray.clear();

          //const checkboxesFormArray = this.fb.array([]); // Novo FormArray para os checkboxes
          const checkboxesFormArray = this.formGroup.get('checkboxs') as FormArray;
          checkboxesFormArray.clear();

          // Adiciona controles ao FormArray dinamicamente
          res.dados.respostas.forEach(item => {            
            respostasFormArray.push(new FormControl(item.resposta));
            checkboxesFormArray.push(new FormControl(item.selected || false));
          });
 
         } else {
          this.exibirMensagemErro(res.mensagem.descricao);
        }
      })
      .catch((err) => this.exibirMensagemErro(err));
  }
  


  toggleSelectAll(checked: boolean) {
    const checkboxesFormArray = this.formGroup.get('checkboxes') as FormArray;
    if (checkboxesFormArray) { 
      const checkboxesFormArray = this.formGroup.get('checkboxes') as FormArray;
      checkboxesFormArray.controls.forEach(control => control.setValue(checked));
  } else {
    return false; // Ou qualquer valor padrão que faça sentido no seu contexto
  }
  }
  
  isAllSelected() {
    const checkboxesFormArray = this.formGroup.get('checkboxes') as FormArray;    
    if (checkboxesFormArray) { // Adicione esta verificação
      return checkboxesFormArray.controls.every(control => control.value);
    } else {
      return false; // Ou qualquer valor padrão que faça sentido no seu contexto
    }
  }
  
  isIndeterminate() {
    const checkboxesFormArray = this.formGroup.get('checkboxes') as FormArray;    
    if (checkboxesFormArray) { // Adicione esta verificação
      const numSelected = checkboxesFormArray.controls.filter(control => control.value).length;
      return numSelected > 0 && numSelected < checkboxesFormArray.controls.length;
    } else {
      return false; // Ou qualquer valor padrão que faça sentido no seu contexto
    }
  }

  updateSelectedItems(item: QuestionarioUGItensResposta) {
    if (item.selected) {
      this.selectedItems.push(item);
    } else {
      const index = this.selectedItems.findIndex(selectedItem => selectedItem === item);
      if (index > -1) {
        this.selectedItems.splice(index, 1);
      }
    }
  }

  onFileSelected(event: any, index: number): void {
    const file: File = event.target.files[0];
    if (file) {
        // Verifica se a extensão do arquivo é PDF
        const fileExtension = file.name.split('.').pop()?.toLowerCase();

        const allowedExtensions = ['pdf', 'jpg', 'jpeg', 'jpe', 'jfif', 'gif', 'png', 'bmp', 'tiff', 'exif', 'doc', 'docx', 'zip', 'csv', 'txt', 'xls', 'ppt', 'pps', 'odt', 'odf', 'html'];

        if (!allowedExtensions.includes(fileExtension)) {
            this.toastr.error('Extensão do arquivo selecionado não é válida !!!', 'Extensão inválida');
            this.arquivo = null; 
        } else {
            this.arquivo = file;
          // AQUI COMEÇA UMA NOVA HISTORIA E OUTRA TENTATIVA 
          const idItemQuestionario = this.dataSource.data[index].idItemQuestionario;

          const arquivoModel: IArquivoModel = {
            id: 0,
            nomeArquivo: this.arquivo.name,
            entidadeArquivo: 1, 
            arquivo: this.arquivo,
            url: ''
          };

          this.questionarioUGService.anexarArquivo(idItemQuestionario ,arquivoModel).then(
            (response) => {
              this.buscar();
              window.location.reload();
            },
            (error) => {
              this.exibirMensagemErro(error.mensagem.descricao);
            }
          );
      }
    }
}


  getRespostaFormControl(index: number): FormControl {
    const respostasFormArray = this.formGroup.get('respostas') as FormArray;
    const control = respostasFormArray.at(index) as FormControl;
    if (this.visualizar) {
      control.disable();
    } else {
      control.enable();
    }
      
    return control;
  }

  getCheckBoxFormControl(index: number): FormControl {
    const checkBoxsFormArray = this.formGroup.get('checkboxs') as FormArray; 
    return checkBoxsFormArray.at(index) as FormControl;
  }

  getArquivoFormControl(index: number): FormControl {
    const arquivosFormArray = this.formGroup.get('arquivos') as FormArray;
    return arquivosFormArray.at(index) as FormControl;
}
  
onCheckboxChange(event: MatCheckboxChange, element: any) {
  
  if (event.checked) {
    this.selectedIds.push(element.idItemQuestionario);
  } else {
    const index = this.selectedIds.indexOf(element.idItemQuestionario);
    if (index > -1) {
      this.selectedIds.splice(index, 1);
    }
  }

  this.habilitaBotaoFinalizar = this.selectedIds.length > 0;

}

isAllFinalized() {
  const filteredItems = this.dataSource.data.filter(item => item.idTipoSecaoOuPergunta === 2);
  const allFinalized = filteredItems.every(item => item.status === 'Finalizado' || item.status === 'Contestado');
  return allFinalized;
}

isStatusEmReplica(){
  return this.model.status == 'Em réplica';
}

isHabilitaBotaoFinalizar(){
  return this.habilitaBotaoFinalizar;
}


}
